/* eslint-disable import/prefer-default-export */

/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const fastStartSuccess = {
  title: "success stories",
  subTitle: "Our merchant",
};

export const fastStartHero = {
  title: "Best-in-class restaurant POS. No upfront costs",
  subtext:
    "For a limited-time, get your hardware free for a single station point-of-sale. Pay only 2.99% + 25c on card transactions and a low monthly software fee—and watch revenue soar.",
  heroImg: "fastStartHero.png",
  imageClassName: "fixed-height-600",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/fast-start/demo",
  },
  fatTitle: "Fast Start",
  fatTitleClass: "full-width",
};

export const fastStartWhiteGlove = {
  mainTitle: "Personalized support that’s working when you are",
  title: "White-glove service & support",
  content:
    "Get the local in-person support your restaurant deserves and around-the-clock technical support. Included at no extra cost.",
  contentList: [
    "Premium in-person installation",
    "Face-to-face training with a SpotOn specialist",
    "Ongoing virtual training for staff and management",
  ],
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/fast-start/demo",
  },
};

export const fastStartLargeFeatures = {
  sectionTitle: "Powerful tools. Customized to your needs",
  featureBlocks: [
    {
      blockTitle: "Built to run and grow your restaurant",
      blockImg: "lp-reserve-2.png",
      blockSubTitle: "",
      blockList: [
        "Lightning-fast front-of-house",
        "Powerful back-of-house",
        "Commission-free online ordering & delivery",
        "Handheld POS & mobile ordering",
        "Seamless integrations with key restaurant tech",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/fast-start/demo",
      },
    },
    {
      blockTitle: "Elite guest data & reporting",
      blockSubTitle: "",
      blockImg: "lp-reporting-2.png",
      blockList: [
        "90+ intuitive reports",
        "Fully interactive and easy to read",
        "Manager alerts & subscriptions",
        "Reporting app to monitor data anywhere, anytime",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/fast-start/demo",
      },
    },
    {
      blockTitle: "Custom POS build",
      blockSubTitle: "",
      blockImg: "lp-rest-3.png",
      blockList: [
        "Dine-in & online menus",
        "Table layouts",
        "Staff profiles",
        "Fully scalable hardware configurations",
        "Marketing & Review Management included",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/fast-start/demo",
      },
    },
  ],
};

export const fastStartTestimonials = {
  title: "What other resaturant owners are saying",
  subTitle:
    "From fine dining to quick-service, restaurant operators trust SpotOn.",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes. I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const fastStartCTA = {
  title: "Book your consultation.",
  ctaImg: "sidekick-cta.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/fast-start/demo",
  },
};

export const fastStartTemplate = [
  {
    content: "Reservations & waitlisting through your website",
    desc: "Keep your tables full and reduce walk-aways by letting your guests book directly from you.",
    img: "Online_reservation.png",
  },
  {
    content: "Sidekick with Google listing",
    desc: "Attract more guests by enabling them to Sidekick a table or add themselves to your guest list right from a web search.",
    img: "Google_listings.png",
  },
  {
    content: "Host application for iPad",
    desc: "Boost efficiency of your front-of-house by easily managing reservations, waitlists, and walk-ins.",
    img: "Host_application.png",
  },
  {
    content: "Server & table management",
    desc: "Streamline operations, reduce labor costs, and turn tables faster with powerful front-of-house tools.",
    img: "Service_management.png",
  },
  {
    content: "Web portal access",
    desc: "Easily manage your front-of-house, wherever you are, including across multiple location.",
    img: "Online_reservation.png",
  },
  {
    content: "Customer analytics",
    desc: "Know who your guests are and where they’re coming from, so you can drive repeat visits.",
    img: "reporting.png",
  },
];
