import React from "react";
import { Typography } from "antd";
import loadable from "@loadable/component";
import ogImage from "../../../images/global_assets/og-image.png";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import Hero from "../../../components/Hero/hero";
import {
  fastStartHero,
  fastStartWhiteGlove,
  fastStartLargeFeatures,
  fastStartCTA,
  fastStartTestimonials,
} from "../../../data/landing/fast-start-data";
// import Badges from "../../../components/ConversionPath/Badges";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import CtaSecondary from "../../../components/Buttons/secondary-cta";

const Badges = loadable(() =>
  import("../../../components/ConversionPath/Badges")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const CtaSecondary = loadable(() =>
  import("../../../components/Buttons/secondary-cta")
);

const { Title } = Typography;

const whiteGloveImport = "spoton-support-white-glove.png";

const FastStart = () => {
  return (
    <LpLayout phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="SpotOn Fast Start| Point Of Sale"
        description="Save hundreds per month with our leading cash discount program that enables you to accept nearly all forms of payment while offsetting card transaction fees."
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />

      <Hero sectionData={fastStartHero} circleBg={false} />
      <section style={{ marginTop: 40 }}>
        <Title level={2} className="text-center">
          Need more stations or multiple locations
          <span className="blue_dot">?</span>
        </Title>
        <div style={{ margin: "0 auto", display: "table" }}>
          <CtaSecondary ctaTitle="Visit here" target="/lp/fast-start/demo" />
        </div>
      </section>
      <section style={{ marginTop: "60px" }}>
        <Badges />
      </section>

      <section style={{ marginTop: "60px" }}>
        <TestmonialReviews sectionData={fastStartTestimonials} />
      </section>

      <PartnerLogos />

      <LargeFeatures
        sectionData={fastStartLargeFeatures}
        style={{ marginTop: "60px" }}
      />

      <WhiteGlove
        sectionData={fastStartWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />

      <LargeCta
        sectionData={fastStartCTA}
        imgClassName="ctaNew"
        style={{ paddingRight: 0 }}
      />
    </LpLayout>
  );
};

export default FastStart;
